import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MintModel, NFTModel } from '../model/nft.model';
import { AppSettings } from '../../app-settings';
import { PolkadotService } from './polkadot.service';
import { environment } from 'src/environments/environment';
import { CookiesService } from './cookies.service';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     Authorization: 'Bearer ' + localStorage.getItem('token'),
//     'websocket': ''
//   }),
// };

@Injectable({
  providedIn: 'root'
})
export class NftService {
  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient,
    private polkadotService: PolkadotService,
    private cookiesService: CookiesService
  ) { }

  public defaultAPIURLHost: string = environment.NFTAPIURL;
  private collectionId = environment.collectionId;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'websocket': this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.network[0].networks[0].wsProviderEndpoint
    }),
  };
  

  getAllNft(): Observable<[boolean, any]> {
      return new Observable<[boolean, any]>((observer) => {

      let nft_list: NFTModel[] = [];
      let collection_id = this.collectionId;
      this.httpClient.post<any>(
          this.defaultAPIURLHost +
          '/nfts/marketplace',
          { collection_id },
          this.httpOptions
      ).subscribe({
          next: (response) => {
          let results = response;

          // let result_data = results['data'];
          if (results != null) {
              var data = results;
              if (data.length > 0) {
              for (let i = 0; i <= data.length - 1; i++) {
                  nft_list.push({
                  nftTokenId: data[i].nftTokenId,
                  imagePath: data[i].imagePath,
                  name:data[i].name,
                  description: data[i].description,
                  price: data[i].price,
                  isForSale: data[i].isForSale,
                  isEquipped: data[i].isEquipped,
                  category: data[i].category,
                  collection: data[i].collection,
                  astroType: data[i].astroType,
                  rarity: data[i].rarity,
                  network: data[i].network,
                  blockchainId: data[i].blockchainId,
                  collectionId: data[i].collectionId,
                  tokenOwner: data[i].tokenOwner,
                  });
              }
              } else {
              nft_list = [];
              }
          }
          observer.next([true, nft_list]);
          observer.complete();
          },
          error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
          }
      });
      });
  }

  getNftById(id: number): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let nft: NFTModel[] = [];
      let nftTokenId = id.toString();
      this.httpClient.get<any>(
        this.defaultAPIURLHost +
        '/nfts/id/' +
        nftTokenId,
        this.httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;

          // let result_data = results['data'];
          if (results != null) {
            nft = results;
          }
          observer.next([true, nft]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }

  async buyNFT(nft: NFTModel, recepient: string, data: any): Promise<Observable<[boolean, any]>> {
    return new Observable<[boolean, any]>((observer) => {
      let id = nft.nftTokenId;
      let to = recepient;
      let from = nft.tokenOwner;
      this.httpClient.post<any>(
        this.defaultAPIURLHost +
        '/nfts/transferfromwoa',
        { from, to, id },
        this.httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;

          if (results != null && results.status === 200) {
            // nft = results;
            this.polkadotService.transferBalanceReady(data);
          }
          observer.next([true, nft]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }
  
  getAllNFTDrops(nft_api: string,websocket:string): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {

      let nft_list: NFTModel[] = [];
      let collection_id = this.collectionId;
      this.httpClient.post<any>(
        nft_api +
        '/nfts/drops',
        { collection_id },
        this.httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;

          // let result_data = results['data'];
          if (results != null) {
            var data = results;
            if (data.length > 0) {
              for (let i = 0; i <= data.length - 1; i++) {
                nft_list.push({
                  nftTokenId: data[i].nftTokenId,
                  imagePath: data[i].imagePath,
                  name:data[i].name,
                  description: data[i].description,
                  price: data[i].price,
                  isForSale: data[i].isForSale,
                  isEquipped: data[i].isEquipped,
                  category: data[i].category,
                  collection: data[i].collection,
                  astroType: data[i].astroType,
                  rarity: data[i].rarity,
                  network: data[i].network,
                  blockchainId: data[i].blockchainId,
                  collectionId: data[i].collectionId,
                  tokenOwner: data[i].tokenOwner,
                });
              }
            }
          }
          observer.next([true, nft_list]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }

  public mintNftDrop(mintModel: MintModel,websocket:string): Observable<[boolean, any]> {
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        websocket: this.cookiesService.getCookieArray('network').wsProviderEndpoint// Include WebSocket header here
      }),
    };
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(
        environment.NFTAPIURL + '/nfts/mint/drop',
        mintModel,
        httpOption
      ).subscribe({
        next: (response) => {
          observer.next([true, response]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.error.message]);
          observer.complete();
        }
      });
    });
  }

}
