import { Routes } from '@angular/router';

export const content: Routes = [
  
  {
    path: "play",
    loadChildren: () => import("../../components/apps/astrochibbi-page/astrochibbi.module").then((m) => m.AstrochibbiModule),
  },

];
