import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/app-settings";
import { environment } from "src/environments/environment";
import { Location } from '@angular/common';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  public top_level_domain: string = 'live';

  constructor(
    private router: Router,
    public appSettings: AppSettings,
    private location: Location
    ) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
    const url = this.location.path();
    // Regular expression to match the domain
    const domainRegex = /\.(co|live|com)\b/i;
  
    // Match the domain using the regex
    const match = url.match(domainRegex);
  
    // Check if a match is found
    if (match && match.length > 1) {
      this.top_level_domain = match[1];
    } else {
      this.top_level_domain = 'live';
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    // {
    //   title: "Games",
    //   icon: "games",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   badgeValue: "4",
    //   active: true,
    //   children: [
    //     { path: "/games/featured", title: "Featured", type: "link" },
    //     { path: "/games/all_games", title: "All Games", type: "link" },
    //     { path: "/games/news_and_events", title: "News & Events", type: "link" },
    //     { path: "/games/ranking", title: "Ranking", type: "link" },
    //   ],
    // },
    { path: "/games", title: "Games", icon: "games", type: "link" },
    {
      title: "NFT",
      icon: "knowledgebase",
      type: "sub",
      badgeType: "light-primary",
      // badgeValue: "3",
      active: false,
      children: [
        // { path: "/games/featured", title: "Featured", type: "link" },
        // { path: "/nft/all_nfts", title: "All NFTs", type: "link" },
        { path: "/nft/drops", title: "Drops", type: "link" },
        { path: environment.MarketplaceURL, title: "Marketplace", icon: "marketplace", type: "link" },
      ],
    },
    // {
    //   title: "Tokens",
    //   icon: "token",
    //   type: "sub",
    //   badgeType: "light-primary",
    //   // badgeValue: "2",
    //   active: false,
    //   children: [
    //     { path: "/token/all_tokens", title: "All Tokens", type: "link" },
    //     { path: "/token/drops", title: "Drops", type: "link" },
    //   ],
    // },
    { path: "/token", title: "Tokens", icon: "tokens", type: "link" },

    // { path: "/home", title: "About", icon: "home", type: "link" },
    // { path: "/home", title: "Assets", icon: "assets", type: "link" },
    // { path: "/wallet", title: "Wallet", icon: "wallet", type: "link" },
    // { path: "https://nft.xgame.live/marketplace", title: "Marketplace", icon: "marketplace", type: "link" },
    // { path: "/games", title: "Games", icon: "games", type: "link" },
    // { path: "/blogs", title: "Blogs", icon: "blog", type: "link" },
    // { path: "/home", title: "Contact", icon: "contact", type: "link" },
  ];
  FOOTERMENUITEMS: Menu[] = [
    { path: "/games", title: "Games", icon: "home", type: "link" },
    { path: "/nft/drops", title: "NFT", icon: "nft", type: "link" },
    { path: environment.MarketplaceURL, title: "Marketplace", icon: "market", type: "link" },
    { path: "/token", title: "Token", icon: "team", type: "link" },
    // { path: "/teams", title: "Teams", icon: "teams", type: "link" },
    // { path: "/blogs", title: "Blogs", icon: "blogs", type: "link" },
  ];

  OTHERMENUITEMS: Menu[] = [
    { path: "/about-us", title: "About Us", icon: "home", type: "link" },
    { path: "/contact-us", title: "Contact Us", icon: "nft", type: "link" },
    { path: "/teams", title: "Teams", icon: "teams", type: "link" },
    { path: "/blogs", title: "Blogs", icon: "blogs", type: "link" },
  ];

  COMMUNITYMENUITEMS: Menu[] = [
    { path: environment.MarketplaceURL, title: "NFT Marketplace", icon: "home", type: "link" },
    { path: environment.DashboardURL, title: "Dashboard", icon: "gem", type: "link" },
  ];

  LEGALMENUITEMS: Menu[] = [
    { path: "/policy/copyright", title: "Copyright Notice", icon: "home", type: "link" },
    { path: "/policy/privacy", title: "Privacy Policy", icon: "gem", type: "link" },
    { path: "/policy/terms", title: "Terms of Use", icon: "gem", type: "link" },
    { path: "/policy/cookie", title: "Cookie Policy", icon: "gem", type: "link" },
    { path: "/policy/legal-disclaimer", title: "Legal Disclaimer", icon: "gem", type: "link" },
  ];

  SOCIALMEDIAMENU: Menu[] = [
    { path: environment.Facebook, title: "Facebook", icon: "social-facebook", type: "link" },
    { path: environment.Instagram, title: "Instagram", icon: "social-instagram", type: "link" },
    { path: environment.Twitter, title: "Twitter", icon: "social-twitter", type: "link" },
    { path: environment.LinkedIn, title: "LinkedIn", icon: "brand-linkedin", type: "link" },
    { path: environment.Discord, title: "Discord", icon: "discord", type: "link" },
    { path: environment.Youtube, title: "Youtube", icon: "youtube-play", type: "link" },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  community_items = new BehaviorSubject<Menu[]>(this.COMMUNITYMENUITEMS);
  legal_items = new BehaviorSubject<Menu[]>(this.LEGALMENUITEMS);
  footer_items = new BehaviorSubject<Menu[]>(this.FOOTERMENUITEMS);
  social_media_items = new BehaviorSubject<Menu[]>(this.SOCIALMEDIAMENU);
  other_items = new BehaviorSubject<Menu[]>(this.OTHERMENUITEMS);
}
